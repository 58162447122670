import "./App.css";
import { Routes, Route } from "react-router-dom";

import AppBar from "./components/AppBar/AppBar";
import Home from "./components/Home/Home";
import AboutAuthor from "./components/AboutAuthor/AboutAuthor";
import Books from "./components/Books/Books";
import Notices from "./components/Notices/Notices";
import NoteDetail from "./components/NoteDetail/NoteDetail";
import BookPage from "./components/BookPage/BookPage";
import Container from "./components/Container/Container";

function App() {
  return (
    <>
      {/* <Container>
        <Routes>
          <Route path="/" element={<AppBar />}>
            <Route index element={<AboutAuthor />} />
            <Route path="/letter-to-author" element={<Home />} />
            <Route path="/books" element={<Books />} />
            <Route path="/books/:id" element={<BookPage />} />
            <Route path="/notices" element={<Notices />} />
            <Route path="/notices/:id" element={<NoteDetail />} />
          </Route>
        </Routes>
      </Container> */}

      <div>
        <p className="coming_soon">Coming soon....</p>
      </div>
    </>
  );
}

export default App;
